import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import {
    PrimaryButton,
    SecondaryButton,
    Form,
    MessageDialog,
    TextField,
} from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import { AddressRequest } from '../api/types';
import {
    ACCOUNTS_CUSTOMERS,
    PEOPLE_ACTIVE,
} from '../../../constans/urlPaths';
import { useCustomer } from '../api/useCustomer';
import PageTitle from '../../../components/PageTitle';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { updateCustomer } from '../../../services/customer';
import { GooglePlacesAutocomplete } from '../../../components/GooglePlacesAutocomplete';
import allValid from '../../../helpers/validation/allValid';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isFilledObject from '../../../helpers/validation/validators/isFilledObject';
import { mapAddressToAddressRequest } from '../../../helpers/maps/mapAddressToAddressRequest';
import { LOCAL_STORAGE_KEYS } from '../../../constans/localStorageKeys';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { BUTTON_SAVE_CUSTOMER, SCREEN_CREATE_EDIT_CUSTOMER, INPUT_ACCOUNT_ADDRESS, INPUT_ACCOUNT_NAME } from '../../../amplitude/amplKeys';

const useStyles = makeStyles({
    formContainer: {
        maxWidth: '442px',
    },
    buttonCancle: {
        marginLeft: '2rem',
    },
    usersButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    title: {
        marginBottom: '3rem',
    },
});

const EditCustomer = () => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const [newAccountName, setNewAccountName] = useState('');
    const [newAddress, setNewAddress] = useState<AddressRequest | string>(
        {} as AddressRequest,
    );
    const [addressError, setAddressError] = useState<InputError | null>(null);
    const [newAccountNameError, setNewAccountNameError] = useState<InputError | null>(
        null,
    );
    const [isOpenModal, setIsOpenModal] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { id = '' } = useParams();
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();

    const { data: customerData, isFetching: isFetchingAccount } = useCustomer(
        parseInt(id, 10),
    );

    useEffect(() => {
        !isFetchingAccount && autoFocusRef.current?.focus();
    }, [autoFocusRef, isFetchingAccount]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_CUSTOMER);
    }, []);

    useEffect(() => {
        logAmplitudeEvent(INPUT_ACCOUNT_ADDRESS);
    }, [newAddress]);

    useEffect(() => {
        if (!customerData) {
            return;
        }
        setNewAccountName(customerData.name);

        location.state = {
            name: customerData.name,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerData]);

    const { mutate: updateAccountMutation, isLoading: isLoadingEdit } = useMutation(
        updateCustomer,
        {
            onSuccess: () => {
                navigate(ACCOUNTS_CUSTOMERS);
                showNotification('Customer successfully edited.', Severity.Info);
            },
            onError: (error: Error) => {
                setIsOpenModal(false);
                showNotification(t('errors.retry'), Severity.Error);
            },
        },
    );

    const validateFormFields = (): boolean => {
        const validated = {
            name: isFilledString(newAccountName, InputError.Required),
            address: isFilledObject(newAddress, InputError.Required),
        };

        if (!allValid(validated)) {
            setAddressError(validated.address.isValid ? null : validated.address?.error);
            setNewAccountNameError(validated.name.isValid ? null : validated.name.error);
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        logAmplitudeEvent(BUTTON_SAVE_CUSTOMER);
        if (validateFormFields()) {
            setIsOpenModal(true);
        }
    };

    const handleSave = () => {
        const address = mapAddressToAddressRequest(newAddress);
        updateAccountMutation({
            id: parseInt(id),
            name: newAccountName,
            address: address,
        });
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <div style={{ position: 'relative' }}>
            <PageTitle title="Edit customer" className={classes.title} />
            <SecondaryButton
                onClick={() => {
                    window.localStorage.setItem(
                        LOCAL_STORAGE_KEYS.ACCOUNT_USERS,
                        customerData?.name ?? '',
                    );
                    navigate(`${PEOPLE_ACTIVE}/${id}`);
                }}
                className={classes.usersButton}
            >
                Users
            </SecondaryButton>
            <Form className={classes.formContainer} onSubmit={handleSubmit}>
                <Grid item xs={12} md={6} xl={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12}>
                                   <TextField
                                        value={newAccountName}
                                        onChange={event => {
                                            setNewAccountName(event.target.value);
                                        }}
                                        label="Account name"
                                        type="text"
                                        autoFocus
                                        autoComplete="username"
                                        name="newAccountName"
                                        required
                                        disabled={isFetchingAccount}
                                        helperText={getHelperText(newAccountNameError, t)}
                                        error={
                                            (!newAccountName && !isFetchingAccount) ||
                                            newAccountNameError !== null
                                        }
                                        onFocus={() =>
                                            logAmplitudeEvent(INPUT_ACCOUNT_NAME)
                                        } 
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <GooglePlacesAutocomplete
                                        error={addressError}
                                        helperText={getHelperText(addressError, t)}
                                        onChangeError={setAddressError}
                                        onChangeAddress={setNewAddress}
                                        label="Address"
                                        defaultValue={customerData?.address}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} marginTop="2rem">
                    <PrimaryButton
                        onClick={handleSubmit}
                        submitsForm
                        loading={isLoadingEdit || isFetchingAccount}
                    >
                        Save
                    </PrimaryButton>
                    <SecondaryButton
                        onClick={handleBack}
                        className={classes.buttonCancle}
                    >
                        Back
                    </SecondaryButton>
                </Grid>
            </Form>
            {isOpenModal && (
                <MessageDialog
                    title="Are you sure you want to edit?"
                    onClose={() => setIsOpenModal(false)}
                    onConfirm={() => handleSave()}
                    closeButtonLabel="Cancel"
                    confirmButtonLabel="Save"
                    isConfirmButtonDisplayed
                    closeButtonType="secondary"
                >
                    <Typography sx={{ padding: '1rem 0' }}>
                        You are about to edit this customer. <br />
                        <strong>Are you sure you want to proceed?</strong>
                    </Typography>
                </MessageDialog>
            )}
        </div>
    );
};

export default EditCustomer;
