import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    NumberedTabs,
    NumberedTab,
    TabPanel,
    Autocomplete,
    SecondaryButton,
} from '@get-e/react-components';
import { Grid, StandardTextFieldProps, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { DateTimeRangePicker, SingleInputDateTimeRangeField, DateRange } from '@mui/x-date-pickers-pro';

import { BookingTab } from '../index';
import {
    BOOKINGS,
    BOOKINGS_ARCHIVE,
    BOOKINGS_IN_PROGRESS,
    BOOKINGS_PENDING,
    BOOKINGS_UPCOMING,
} from '../../../constans/urlPaths';
import PageTitle from '../../../components/PageTitle';
import List from './bookingsList/List';
import { BookingConfirmation, BookingStatus, SearchOption } from '../api/types';
import { setSearch } from '../../../store/searchSlice';
import { RootState } from '../../../store/store';
import { toUpperCaseFirstLetter } from '../../../helpers/toUpperCaseFirstLetter';
import { COLORS } from '../../../constans/colors';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { useBookingSearch } from '../api/useBookingSearch';
import {useSearchOptionsForBooking} from "../api/useSearchOptionsForBooking";
import { useMutation } from 'react-query';
import { confirm } from '../../../services/HotelBooking';
import { useNotificationContext } from '../../../context/NotificationContext';
import { getBookingStatusKeyName } from '../../../helpers/bookingStatusUtil';
import { useIsHotelUser } from '../../../context/AuthenticatedUserContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { setDateRangeFilter } from '../../../store/dateRangeFilterSlice';
import { logAmplitudeBookingTab } from '../../../helpers/logAmplitudeTab';
import { INPUT_BOOKINGS_SEARCH, INPUT_BOOKINGS_SELECT_DATE_RANGE, SCREEN_BOOKINGS_LIST } from '../../../amplitude/amplKeys';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../../styles/DateTimeRangePicker';

const useStyles = makeStyles(() => ({
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    searchBox: {
        marginBottom: '2rem',
    },
    saveButton: {
        width: "100%",
        height: "2rem",
        marginTop: "-0.3rem"
    }
}));

const getActiveTab = (tab: number) => {
    if (tab === BookingTab.All) return 'all';
    else if (tab === BookingTab.Pending) return 'pending';
    else if (tab === BookingTab.Archive) return 'archive';
    else if (tab === BookingTab.InProgress) return 'in_progress';
    else if (tab === BookingTab.Upcoming) return 'upcoming';
};

interface BookingsListProps {
    tab: BookingTab;
}

let searchTimeoutId: ReturnType<typeof setTimeout> | number = -1;
var isInitalHotelChanged = false;

const BookingsList = ({ tab }: BookingsListProps) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isHotelUser = useIsHotelUser();
    const [page, setPage] = useState(1);
    const { showNotification } = useNotificationContext();
    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const searchGlobal = useSelector((state: RootState) => state.search.value);
    const dateRange = useSelector((state: RootState) => isHotelUser && !isInitalHotelChanged ? [null, null] as DateRange<Moment> : state.dateRangeFilter.value);

    const {
        data: res,
        isLoading: isLoadingBookings,
        isRefetching: isRefetchingBookings,
    } = useBookingSearch({
        page,
        activeTab: getActiveTab(tab),
        searchType:
            (searchGlobal?.group_type as 'traveller' | 'address' | 'hotel') ??
            '',
        searchValue: searchGlobal?.search_value ?? '',
        startDate: dateRange?.[0] ? moment(dateRange[0])
            .format(DATE_FORMATS["YYYY-MM-DDTHH:mm:ss"]) : '',
        endDate: dateRange?.[1] ? moment(dateRange[1])
            .format(DATE_FORMATS["YYYY-MM-DDTHH:mm:ss"]) : '',
    });

    const meta = res?.meta;
    const loading = isLoadingBookings || isRefetchingBookings;

    const {
        data: searchOptions,
        isLoading,
    } = useSearchOptionsForBooking(debouncedSearchPhrase);

    const options = useMemo(() => {
        const allOptions = [
            searchOptions?.traveller ?? [],
            searchOptions?.address ?? [],
            searchOptions?.hotel ?? [],
        ];
        return allOptions?.flat();
    }, [searchOptions]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_BOOKINGS_LIST);
    }, [tab]);

    useEffect(() => {
        logAmplitudeBookingTab(tab);
    }, [tab]);

    const handleSearchInputOnChange = useCallback(
        (value: string) => {
          clearTimeout(searchTimeoutId);
          searchTimeoutId = setTimeout(() => {
            setDebouncedSearchPhrase(value);
          }, 600);
        },
        [setDebouncedSearchPhrase]
    );   

    const map = new Map<number, BookingConfirmation>();

    const updateBookingsChangeList = (id: number, status:BookingStatus, confirmationNumber: string, hotelComments: string) => {
        map.set(
            id, 
            {id:id, status:getBookingStatusKeyName(status), bookingNumber:confirmationNumber, hotelComments:hotelComments}
        );
    };

    const removeFromUpdateList = (id: number) => {
        map.delete(id)
    };

    const { mutate: confirmBookings, isLoading: isLoadingEdit } = useMutation(
        confirm,
        {
            onSuccess: () => {
                map.clear();
                window.location.reload();
            },
            onError: (error: Error) => {
                showNotification("Something went wrong!");
            },
        },
    );

    const confirmAll = () => {
        if (map.size === 0) {
            return;
        }
        confirmBookings({payload: {
            list: Array.from(map.values())
        }})
    };

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <PageTitle title="Bookings" />
                </Grid>

                <Grid item xs={6} marginBottom="1rem">
                    <DateTimeRangePicker
                        sx={dateTimeRangePickerStyles}
                        label={"Date range"}
                        value={dateRange}
                        onChange={newValue => {
                            dispatch(setDateRangeFilter(newValue));
                            isInitalHotelChanged = true;
                            logAmplitudeEvent(INPUT_BOOKINGS_SELECT_DATE_RANGE)
                        }}
                        slotProps={{
                            textField: textFieldStyles as StandardTextFieldProps,
                            field: { clearable: true },
                            day: {
                              sx: dayStyles,
                            },
                        }}
                        slots={{ field: SingleInputDateTimeRangeField }}
                        format={DATE_FORMATS["DD MMM YYYY HH:mm A"]}
                    />
             </Grid>
            </Grid>

            <Autocomplete
                label="Search by hotel, traveller or address"
                includeInputInList
                options={options ?? []}
                groupBy={(option: SearchOption) =>
                    toUpperCaseFirstLetter(option.group_type)
                }
                getOptionLabel={option =>
                    option
                        ? option.main_display_value +
                          (option.secondary_display_value
                              ? `, ${option.secondary_display_value}`
                              : '')
                        : ''
                }
                renderOption={(prop, option) => (
                    <Box component="li" {...prop}>
                        <span>{option.main_display_value}</span>
                        {option.secondary_display_value && (
                            <span className={classes.secondaryText}>
                                &nbsp;{option.secondary_display_value}
                            </span>
                        )}
                    </Box>
                )}
                value={searchGlobal}
                onChange={(_, newValue) => {
                    dispatch(setSearch(newValue));
                    logAmplitudeEvent(INPUT_BOOKINGS_SEARCH)
                }}
                onInputChange={(_, newInputValue) => {
                    handleSearchInputOnChange(newInputValue);
                }}
                className={classes.searchBox}
                loading={isLoading}
            />

            <Grid container>
                <Grid item xs={10.5} marginBottom={1}>
                    <NumberedTabs value={tab}>
                        <NumberedTab
                            number={res?.counts?.all ?? 0}
                            label="All"
                            url={BOOKINGS}
                            index={BookingTab.All}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={res?.counts?.pending ?? 0}
                            label="Pending"
                            url={BOOKINGS_PENDING}
                            index={BookingTab.Pending}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={res?.counts?.upcoming ?? 0}
                            label="Upcoming"
                            url={BOOKINGS_UPCOMING}
                            index={BookingTab.Upcoming}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={res?.counts?.inProgress ?? 0}
                            label="In progress"
                            url={BOOKINGS_IN_PROGRESS}
                            index={BookingTab.InProgress}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                        <NumberedTab
                            number={res?.counts?.archive ?? 0}
                            label="Archive"
                            url={BOOKINGS_ARCHIVE}
                            index={BookingTab.Archive}
                            selectedIndex={tab}
                            loading={loading}
                            isHeighterNumberHidden
                        />
                    </NumberedTabs>
                </Grid>
                <Grid item xs={isMobile ? 12 : 1.5} marginBottom={1}
                    style={{
                        padding: "0.7rem"
                    }}
                >
                {isHotelUser && <SecondaryButton
                    onClick={confirmAll}
                    className={classes.saveButton}
                >
                    Save All
                </SecondaryButton>}
                                    
                </Grid>
            </Grid>
            <TabPanel value={BookingTab.All} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                    updateBookingsChangeList={updateBookingsChangeList} 
                    removeFromUpdateList={removeFromUpdateList}
                />
            </TabPanel>
            <TabPanel value={BookingTab.Pending} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                    updateBookingsChangeList={updateBookingsChangeList} 
                    removeFromUpdateList={removeFromUpdateList}
                />
            </TabPanel>
            <TabPanel value={BookingTab.Upcoming} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                    updateBookingsChangeList={updateBookingsChangeList} 
                    removeFromUpdateList={removeFromUpdateList}
                />
            </TabPanel>
            <TabPanel value={BookingTab.InProgress} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                    updateBookingsChangeList={updateBookingsChangeList} 
                    removeFromUpdateList={removeFromUpdateList}
                />
            </TabPanel>
            <TabPanel value={BookingTab.Archive} selectedValue={tab}>
                <List
                    res={res?.data}
                    setPage={setPage}
                    countPages={meta?.last_page}
                    isLoadingBookings={isLoadingBookings}
                    isRefetchingBookings={isRefetchingBookings}
                    updateBookingsChangeList={updateBookingsChangeList} 
                    removeFromUpdateList={removeFromUpdateList}
                />
            </TabPanel>
        </>
    );
};

export default BookingsList;
