import {Download, Delete} from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams, GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constans/colors';
import useDataGridStyles from '../../../styles/DataGrid';
import { ChangeStatus } from '../types';
import { StatusButton } from '../components/Status';
import { useAuth } from '../../../context/AuthenticatedUserContext';

const useStyles = makeStyles({ redMenuItem: { color: COLORS.RED } });

const COLUMN_WIDTH = 200;

export const useInvoicesColumns = (
) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dataGridClases = useDataGridStyles();
    const { user } = useAuth();

    const onActionItemClick = useCallback(
        (vehicle: any, action: ChangeStatus) => {
            switch (action) {
                case ChangeStatus.DOWNLOAD_STATEMENTS:
                    break;
                case ChangeStatus.DOWNLOAD_INVOICES:
                    break;
                case ChangeStatus.WITHDRAW:
                    break;
                default:
                    throw new Error('Driver action not supported');
            }
        },
        []
    );

    return useMemo(
        () => [
            { field: 'invoiceRef', headerName: 'Invoice reference', minWidth: COLUMN_WIDTH, flex: 1 },
            { field: 'relation', headerName: 'Relation', minWidth: COLUMN_WIDTH, flex: 1  },
            { field: 'issuedOn', headerName: 'Issued on', minWidth: COLUMN_WIDTH, flex: 1  },
            { field: 'dueOn', headerName: 'Due on', minWidth: COLUMN_WIDTH, flex: 1  },
            { field: 'invoiceAmount', headerName: 'Invoice Amount', minWidth: COLUMN_WIDTH, flex: 1  },
            { field: 'platformAmount', headerName: 'Platform Amount', minWidth: COLUMN_WIDTH, flex: 1  },
            {
              field: 'status',
              headerName: 'Status',
              width: 130,
              renderCell: (params: GridRenderCellParams) => (
                <StatusButton status={params.row.status}/>
              ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 20,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => {
                    const vehicle = params.row as any;
                    const actionItems: JSX.Element[] = [];

                    actionItems.push( <GridActionsCellItem
                        key={ChangeStatus.DOWNLOAD_INVOICES}
                        className={dataGridClases.menuItem}
                        label={'Download inovice files'}
                        onClick={() => onActionItemClick(vehicle, ChangeStatus.DOWNLOAD_INVOICES)}
                        showInMenu
                        icon={<Download fontSize="small" style={{ fill: COLORS.BLUE_DARK }} />}
                    />)

                    if(user?.customer || user?.isBackoffice){
                        actionItems.push(
                            <GridActionsCellItem
                            key={ChangeStatus.DOWNLOAD_STATEMENTS}
                            className={dataGridClases.menuItem}
                            icon={<Download fontSize="small" style={{ color: COLORS.BLUE_DARK }} />}
                            label={'Download statements'}
                            onClick={() => onActionItemClick(vehicle, ChangeStatus.DOWNLOAD_STATEMENTS)}
                            showInMenu
                            />
                        )
                    }
                    
                    if(user?.hotel || user?.isBackoffice) {
                        actionItems.push(
                            <GridActionsCellItem
                                key={ChangeStatus.WITHDRAW}
                                className={classes.redMenuItem}
                                label={'Withdraw invoice'}
                                onClick={() => onActionItemClick(vehicle, ChangeStatus.WITHDRAW)}
                                showInMenu
                                icon={<Delete fontSize="small" style={{ fill: COLORS.RED }} />}
                            />
                        )
                    }                

                    return actionItems;
                },
            },
        ],
        [dataGridClases.hideSeparator, dataGridClases.menuItem, user?.customer, user?.isBackoffice, user?.hotel, onActionItemClick, classes.redMenuItem]
    );
};
