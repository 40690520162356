import {
    TextField,
} from '@get-e/react-components';
import { Grid, InputAdornment, StandardTextFieldProps, Typography } from '@mui/material';
import { DateTimeRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import { useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { COLORS } from '../../constans/colors';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../styles/DateTimeRangePicker';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import { columns, mockData } from './mockData';

const Statements = () => {
    const [searchPhrase, setSearchPhrase] = useState('');

    return (
        <Grid container alignItems="flex-start">
        <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    Statements
                </Typography>
            </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Grid item xs={12} md={8} xl={8}>
                <TextField
                    style={{ marginBottom: 0 }}
                    type="text"
                    autoComplete="off"
                    value={searchPhrase}
                    noHelperTextSpace
                    onChange={event => {
                        setSearchPhrase(event.target.value);
                    }}
                    label="Search by relation"
                    name="search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />} */}
                            </InputAdornment>
                        ),
                        onBlur: event => setSearchPhrase(event.target.value),
                    }}
                    onBlur={event => setSearchPhrase(event.target.value)}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                xl={4}
                sx={{
                    paddingLeft: ['0', '0', '2rem'],
                    marginTop: ['1rem', '1rem', 0],
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
            <DateTimeRangePicker
                sx={dateTimeRangePickerStyles}
                label={"Date range"}
                onChange={newValue => {}}
                slotProps={{
                    textField: textFieldStyles as StandardTextFieldProps,
                    field: { clearable: true },
                    day: {
                      sx: dayStyles,
                    },
                }}
                slots={{ field: SingleInputDateTimeRangeField }}
                format={DATE_FORMATS["DD MMM YYYY HH:mm A"]}
            />
            </Grid>
        </Grid>
        <Grid container>
            <DataGridPro
                disableColumnSelector
                disableColumnFilter
                rows={mockData}
                columns={columns as GridColDef[]}
                pagination
            />
        </Grid>
    </Grid>
    );
};

export default Statements;
