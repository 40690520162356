
 
import {
    PrimaryButton,
    TextField,
} from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import { Grid, InputAdornment, StandardTextFieldProps, FormControl, FormControlLabel, Checkbox, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimeRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro';
import React, { useState } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../../constans/colors';
import { dateTimeRangePickerStyles, dayStyles, textFieldStyles } from '../../styles/DateTimeRangePicker';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import { mockData } from './mockData';
import { useInvoicesColumns } from './hooks/useInvoicesColumns';
import { UPLOAD_INVOICE } from '../../constans/urlPaths';

const useStyles = makeStyles(() => ({
    }));

const Invoices = () => {
    const classes = useStyles();
    const [searchPhrase, setSearchPhrase] = useState('');
    const navigate = useNavigate();
    const [status, setStatus] = useState({
        matched: true,
        noMatch: true,
      });

    const columns = useInvoicesColumns();

    const handleUploadNewInovoice = () => navigate(UPLOAD_INVOICE);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStatus({
          ...status,
          [event.target.name]: event.target.checked,
        });
      };

    return (
        <Grid container alignItems="flex-start">
        <Grid container justifyContent="space-between" alignItems="flex-start">
            <Grid item>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    Invoices
                </Typography>
            </Grid>
            <Grid item>
                    <PrimaryButton onClick={handleUploadNewInovoice} icon={<AddIcon />}>
                       Upload Invoices
                    </PrimaryButton>
                </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Grid item xs={12} md={8} xl={8}>
                <TextField
                    style={{ marginBottom: 0 }}
                    type="text"
                    autoComplete="off"
                    value={searchPhrase}
                    noHelperTextSpace
                    onChange={event => {
                        setSearchPhrase(event.target.value);
                    }}
                    label="Search by relation"
                    name="search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* {isLoading || isRefetching ? <Spinner size={16} /> : <SearchIcon />} */}
                            </InputAdornment>
                        ),
                        onBlur: event => setSearchPhrase(event.target.value),
                    }}
                    onBlur={event => setSearchPhrase(event.target.value)}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                xl={4}
                sx={{
                    paddingLeft: ['0', '0', '2rem'],
                    marginTop: ['1rem', '1rem', 0],
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
            <DateTimeRangePicker
                sx={dateTimeRangePickerStyles}
                label={"Date range"}
                onChange={newValue => {}}
                slotProps={{
                    textField: textFieldStyles as StandardTextFieldProps,
                    field: { clearable: true },
                    day: {
                      sx: dayStyles,
                    },
                }}
                slots={{ field: SingleInputDateTimeRangeField }}
                format={DATE_FORMATS["DD MMM YYYY HH:mm A"]}
            />
            </Grid>
        </Grid>
        <Grid marginBottom={'.5rem'} display="flex" alignItems="center">
                <Typography variant="body1" style={{ marginRight: '8px' }}>
                    Filter by status:
                </Typography>
                <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={status.matched}
                        onChange={handleCheckboxChange}
                        name="matched"
                        color="primary"
                        />
                    }
                    label="Matched"
                    />
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={status.noMatch}
                        onChange={handleCheckboxChange}
                        name="noMatch"
                        color="primary"
                        />
                    }
                    label="No match"
                    />
                </FormControl>
        </Grid>
        <Grid container>
            <DataGridPro
                disableColumnSelector
                disableColumnFilter
                rows={mockData}
                columns={columns as GridColDef[]}
                pagination
            />
        </Grid>
    </Grid>
    );
};

export default Invoices;
