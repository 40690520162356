import React, { ChangeEvent, useState } from 'react';
import { Card, CardContent, Grid, Typography, IconButton, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PrimaryButton, SecondaryButton, TextField } from '@get-e/react-components';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { Moment } from 'moment';
import {InfoOutlined, DeleteOutline} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../../constans/colors';
import { DATE_FORMATS } from '../../helpers/dateFormats';
import LabeledValue from '../../components/LabeledValue';
import InputError from '../../helpers/validation/InputError';
import isFilledString from '../../helpers/validation/validators/isFilledString';
import isNotNull from '../../helpers/validation/validators/isNotNull';
import getHelperText from '../../helpers/validation/getHelperText';
import isFilledArray from '../../helpers/validation/validators/isFilledArray';
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  heading: {
    color: COLORS.BLUE,
    fontSize: '1rem',
    fontWeight: 700,
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center'
  },
  helperText: {
    color: COLORS.RED
  },
  secondHeading: {
    color: COLORS.SLATE_GREY,
    fontSize: '.8rem',
    fontWeight: 700,
    marginBottom: '.8rem',
  },  
  icon: {
    fontSize: '1.5rem',
    marginLeft: '0.5rem',
  },
  mr1: {
    marginRight: '1rem'
  },
  formField: { marginBottom: '1.5rem' },
  fileContainer: {
    backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    padding: '.5rem 1rem',
    marginBottom: '1.5rem',
    marginRight: '1rem',
  },
  fileName: {
    color: COLORS.BLUE_TEXT,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  infoText: {
    fontSize: '0.75rem',
    color: '#707070',
  },
}));

interface FormFields {
  invoiceReference: string;
  comments: string;
  invoiceDate: Moment | null;
  dueDate: Moment | null;
  totalAmount: string;
  totalRoomNights: number | null;
  relationName: string;
}

interface FormErrors {
  invoiceReference:  InputError | null;
  invoiceDate: InputError | null;
  dueDate: InputError | null;
  totalAmount:  InputError | null;
  totalRoomNights:  InputError | null;
  files:  InputError | null;
}

const UploadInvoice = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchRelationNameTerm, setSearchRelationNameTerm] = useState('');

  const [files, setFiles] = useState<File[]>([]);

  const [formValues, setFormValues] = useState<FormFields>({
    invoiceReference: '',
    comments: '',
    invoiceDate: null,
    dueDate: null,
    totalAmount: '',
    totalRoomNights: null,
    relationName: ''
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({
    invoiceReference: null,
    invoiceDate: null,
    dueDate: null,
    totalAmount: null,
    totalRoomNights: null,
    files: null
  }); 

  const [isOpenPicker, setIsOpenPicker] = useState({
    invoiceDate: false,
    dueDate: false,
  });

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFiles((prevFiles) => [...prevFiles, ...Array.from(selectedFiles)]);
    }

    setFormErrors(prevStateForm => ({
      ...prevStateForm,
      files: null,
  }));
  };

  const handleRemoveFile = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setFormErrors(prevStateForm => ({
      ...prevStateForm,
      [name]: null,
  }));
  };

  const handleDateChange = (date: Moment | null, fieldName: 'invoiceDate' | 'dueDate') => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));

    setFormErrors(prevStateForm => ({
        ...prevStateForm,
        [fieldName]: null,
    }));
  };

  const handlePickerShow = (name: 'invoiceDate' | 'dueDate') => {
    setIsOpenPicker((prevValues) => ({
      ...prevValues,
      [name]: !prevValues[name],
    }));
  };

  const validateFields = (): boolean => {
    const validatedReference = isFilledString(formValues.invoiceReference, InputError.Required);
    const validatedDate = isNotNull(formValues.invoiceDate, InputError.Required);
    const validatedDueDate = isNotNull(formValues.dueDate, InputError.Required);
    const validatedAmount = isFilledString(formValues.totalAmount, InputError.Required);
    const validatedRoomNights = isNotNull(formValues.totalRoomNights, InputError.Required);
    const validatedFiles = isFilledArray(files, InputError.Required);

    const fieldErros: FormErrors = {
      invoiceReference: validatedReference.isValid ? null : validatedReference.error,
      invoiceDate: validatedDate.isValid ? null : validatedDate.error,
      dueDate: validatedDueDate.isValid ? null : validatedDueDate.error,
      totalAmount: validatedAmount.isValid ? null : validatedAmount.error,
      totalRoomNights: validatedRoomNights.isValid ? null : validatedRoomNights.error,
      files: validatedFiles.isValid ? null : validatedFiles.error,
    };

    const isValid = Object.values(fieldErros).every(error => error === null);

    !isValid && setFormErrors(fieldErros);

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateFields()) {
      return;
    }
  }

  const handleBack = () => navigate(-1)

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h3" component="h4" 
                    className={clsx(classes.heading, 
                    {[classes.helperText]: formErrors.files !== null})}>
                  Select file(s) *
                </Typography>
                {files.map((file, index) => (
                  <Grid container key={index}>
                    <Grid item xs className={classes.fileContainer}>
                      <Typography fontSize={'.7rem'} color={COLORS.SLATE_GREY}>File</Typography>
                      <Typography className={classes.fileName}>{file.name}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleRemoveFile(file.name)}
                      >
                        <DeleteOutline sx={{color: COLORS.BLUE_DARK}}/>
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Typography marginBottom="1rem" sx={{ color: formErrors.files !==null ? COLORS.RED : ''}}>
                  * Minimum 1 file required
                </Typography>
                <Button 
                  variant="outlined"
                  component="label"
                >
                  Select file
                  <input
                    type="file"
                    accept=".pdf,.xls,.xlsx"
                    multiple
                    hidden
                    onChange={handleFileChange}
                    value={''}
                  />
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card variant="outlined">
              <CardContent>
              <Typography variant="h3" component="h4" className={classes.heading}>
                Select bookings on invoice
              </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h3" component="h4" className={classes.heading}>
              Invoice details
            </Typography>
            <TextField
              label="Invoice reference"
              name="invoiceReference"
              value={formValues.invoiceReference}
              onChange={handleInputChange}
              autoComplete="off"
              required
              error={formErrors.invoiceReference !== null}
              helperText={getHelperText(formErrors.invoiceReference, t)}
              className={formErrors.invoiceReference !== null ? '' : classes.formField }
            />
            <DatePicker
              label="Invoice date"
              value={formValues.invoiceDate}
              onChange={(date) => handleDateChange(date, 'invoiceDate')}
              format={DATE_FORMATS['DD MMM YYYY']}
              slotProps={{
                textField: {
                  variant: 'filled',
                  required: true,
                  onClick: () => handlePickerShow('invoiceDate'),
                  error: formErrors.invoiceDate !== null,
                  helperText: getHelperText(formErrors.invoiceDate, t)
                },
              }}
              open={isOpenPicker.invoiceDate}
              onClose={() => handlePickerShow('invoiceDate')}
              className={formErrors.invoiceDate !== null ? '' : classes.formField }
              sx={{ width: '100%'}}
            />
            <DatePicker
              label="Due date"
              value={formValues.dueDate}
              onChange={(date) => handleDateChange(date, 'dueDate')}
              format={DATE_FORMATS['DD MMM YYYY']}
              slotProps={{
                textField: {
                  variant: 'filled',
                  required: true,
                  onClick: () => handlePickerShow('dueDate'),
                  error: formErrors.dueDate !== null,
                  helperText: getHelperText(formErrors.dueDate, t)
                },
              }}
              open={isOpenPicker.dueDate}
              onClose={() => handlePickerShow('dueDate')}
              className={formErrors.dueDate !== null ? '' : classes.formField }
              sx={{ width: '100%'}}
            />
            <TextField
              label="Comments (optional)"
              name="comments"
              value={formValues.comments}
              onChange={handleInputChange}
              InputProps={{
                minRows: '3',
                multiline: true,
              }}
              placeholder='Add any comments here'
            />
            <Typography variant="h3" component="h4" className={classes.heading}>
              Amount
             <InfoOutlined className={classes.icon} />
            </Typography>
            <TextField
              label="Invoice total amount"
              name="totalAmount"
              value={formValues.totalAmount}
              onChange={handleInputChange}
              autoComplete="off"
              required
              error={formErrors.totalAmount !== null}
              helperText={getHelperText(formErrors.totalAmount, t)}
              className={formErrors.totalAmount !== null ? '' : classes.formField }
            />
            <Grid item xs={12} display={"flex"} flexDirection={'row'} rowGap={'1rem'} marginBottom={'1rem'}>
              <Grid item xs={12}>
                  <LabeledValue label={'Statement period'} value={'150,000.00 CAD'} />
              </Grid>
              <Grid item xs={12}>
                  <LabeledValue label={'Statement period difference'} value={'0,00 CAD'} />
              </Grid>
            </Grid>
            <Typography variant="h3" component="h4" className={classes.heading}>
              Room nights
             <InfoOutlined className={classes.icon} />
            </Typography>
            <TextField
              label="Invoice total room nights"
              name="totalRoomNights"
              value={formValues.totalRoomNights}
              onChange={handleInputChange}
              autoComplete="off"
              required
              error={formErrors.totalRoomNights !== null}
              helperText={getHelperText(formErrors.totalRoomNights, t)}
              className={formErrors.totalRoomNights !== null ? '' : classes.formField }
            />
            <Grid item xs={12} display={"flex"} flexDirection={'row'} rowGap={'1rem'}>
                <Grid item xs={12}>
                  <LabeledValue label={'Statement period total'} value={'150,000.00 CAD'} />
                </Grid>
                <Grid item xs={12}>
                  <LabeledValue label={'Statement period difference'} value={'0,00 CAD'} iconElement={<InfoOutlined style={{ fontSize: '1rem'}}/>}/>
                </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <PrimaryButton
              onClick={handleSubmit}
              className={classes.mr1}
        >
              Upload
          </PrimaryButton>
          <SecondaryButton onClick={handleBack}>
              Back
          </SecondaryButton>
      </Grid>
    </Grid>
  );
};

export default UploadInvoice;
