import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    PrimaryButton,
    SecondaryButton,
    MessageDialog,
    TextField,
    MultipleSelect,
    FullScreenLoader,
} from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
} from '@mui/material';
import clsx from 'clsx';

import { HOTEL_USERS_ACTIVE, PEOPLE_ACTIVE } from '../../../constans/urlPaths';
import PageTitle from '../../../components/PageTitle';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { useUser } from '../api/useUser';
import { updateUser } from '../../../services/users';
import { useAuth } from '../../../context/AuthenticatedUserContext';
import allValid from '../../../helpers/validation/allValid';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import isFilledArray from '../../../helpers/validation/validators/isFilledArray';
import useEffectAsync from '../../../helpers/useEffectAsync';
import { listCustomerRoles } from '../../../services/customer';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { User } from '../api/types';
import { listHotelRoles } from '../../../services/hotel';
import { NotificationSetting } from '../../reservations/api/types';
import { BUTTON_SAVE_USER, SCREEN_EDIT_USER, INPUT_FIRST_NAME, INPUT_LAST_NAME, INPUT_SELECT_ROLE, BUTTON_DEACTIVATE_OR_ACTIVATE_USER } from '../../../amplitude/amplKeys';

const useStyles = makeStyles({
    formContainer: {
        maxWidth: '442px',
    },
    buttonCancel: {
        marginLeft: '2rem',
    },
    title: {
        marginBottom: '2rem',
    },
    formField: {
        marginBottom: '1rem',
    },
});

const EditPeople = () => {
    const autoFocusRef = useRef<HTMLInputElement>();
    const [userRoles, setUserRoles] = useState({} as any);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [deactivateUser, setDeactivateUser] = useState(false);
    const [firstNameError, setFirstNameError] = useState<InputError | null>(null);
    const [lastNameError, setLastNameError] = useState<InputError | null>(null);
    const [roleIdsError, setRoleIdsError] = useState<InputError | null>(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { showNotification } = useNotificationContext();
    const classes = useStyles();
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { user, onChangeRole } = useAuth();
    const { t } = useTranslation();
    const [notificationSettings, setNotificationSettings] = useState<string[]>([]);

    const { data: userData, isFetching: isFetchingUser } = useUser(parseInt(id, 10));

    useEffect(() => {
        !isFetchingUser && autoFocusRef.current?.focus();
    }, [autoFocusRef, isFetchingUser]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_EDIT_USER);
    }, []);

    useEffectAsync(async () => {
        if (userData) {
            const customerRoles = userData?.customer
            ? await listCustomerRoles(userData?.customer.id)
            : await listHotelRoles(userData?.hotel.id);

            setFirstName(userData.firstName);
            setLastName(userData.lastName);
            userData.roles.length &&
                setUserRoles({
                    roles: new Map(
                        customerRoles.map(role => [
                            role.id.toString(),
                            role.name,
                        ]),
                    ),
                    defaultSelectedRoles: userData?.roles.map(role => role.name),
                });
            setDeactivateUser(!userData.isActive);
            setNotificationSettings(userData.notificationSettings)
        }
    }, [userData]);

    const { mutate: updateUserMutation, isLoading: isLoadingEdit } = useMutation(
        updateUser,
        {
            onSuccess: (data: User) => {
                showNotification('User successfully edited.', Severity.Info);
                if (user?.id === data.id) {
                    onChangeRole(data);
                }

                if (data.hotel !== null) {
                    navigate(user?.hotel?.id === data.hotel.id ? HOTEL_USERS_ACTIVE : `${HOTEL_USERS_ACTIVE}/${data.hotel.id}`);
                } else {
                    navigate(user?.customer?.id === data.customer.id ? PEOPLE_ACTIVE : `${PEOPLE_ACTIVE}/${data.customer.id}`);
                }
            },
            onError: (error: Error) => {
                setIsOpenModal(false);
                showNotification(t('errors.retry'), Severity.Error);
            },
        },
    );

    const onSetIds = (ids: string[]) => {
        setRoleIds(ids);
        setRoleIdsError(null);
    };

    const onSetNotificationSettings = (selected: string[]) => {
        setNotificationSettings(selected);
    };

    const validateFormFields = (): boolean => {
        setFirstNameError(null);
        setLastNameError(null);
        setRoleIdsError(null);

        const validated = {
            firstName: isFilledString(firstName, InputError.Required),
            lastName: isFilledString(lastName, InputError.Required),
            roleIds: isFilledArray(roleIds, InputError.Required),
        } as any;

        if (!allValid(validated)) {
            setFirstNameError(
                validated.firstName.isValid ? null : validated.firstName.error,
            );
            setLastNameError(
                validated.lastName.isValid ? null : validated.lastName.error,
            );
            setRoleIdsError(validated.roleIds.isValid ? null : validated.roleIds.error);

            return false;
        }

        return true;
    };

    const handleSave = () => {
        logAmplitudeEvent(BUTTON_SAVE_USER);
        if (validateFormFields()) {
            setIsOpenModal(true);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleConfirmSave = () => {
        updateUserMutation({
            id: parseInt(id),
            isActive: !deactivateUser,
            firstName: firstName,
            lastName: lastName,
            roleIds: roleIds,
            notificationSettings: notificationSettings
        });
    };

    if (isFetchingUser || !userRoles.roles) {
        return <FullScreenLoader />;
    }

    return (
        <div style={{ position: 'relative' }}>
            <PageTitle
                title={`${userData?.firstName} ${userData?.lastName}`}
                className={classes.title}
            />
            <Grid item xs={12} md={6} xl={6}>
                <Card variant="outlined" className={classes.formContainer}>
                    <CardContent>
                        <Grid container columnSpacing={1} rowSpacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    value={firstName}
                                    onChange={event => {
                                        setFirstName(event.target.value);
                                        setFirstNameError(null);
                                    }}
                                    label="First name"
                                    type="text"
                                    autoFocus
                                    name="firstName"
                                    inputRef={autoFocusRef}
                                    error={firstNameError !== null}
                                    helperText={getHelperText(firstNameError, t)}
                                    className={clsx(classes.formField)}
                                    required
                                    onFocus={() =>
                                        logAmplitudeEvent(
                                            INPUT_FIRST_NAME,
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={lastName}
                                    onChange={event => {
                                        setLastName(event.target.value);
                                        setLastNameError(null);
                                    }}
                                    label="Last name"
                                    type="text"
                                    name="lastName"
                                    error={lastNameError !== null}
                                    helperText={getHelperText(lastNameError, t)}
                                    className={clsx(classes.formField)}
                                    required
                                    onFocus={() =>
                                        logAmplitudeEvent(
                                            INPUT_LAST_NAME,
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultipleSelect
                                    label="Role"
                                    value={roleIds}
                                    required
                                    error={roleIdsError !== null}
                                    helperText={getHelperText(roleIdsError, t)}
                                    values={userRoles.roles}
                                    onSetIds={onSetIds}
                                    disabled={!userRoles.roles}
                                    defaultSelectedValue={userRoles.defaultSelectedRoles}
                                    onFocus={() =>
                                        logAmplitudeEvent(INPUT_SELECT_ROLE)
                                    }
                                />
                            </Grid>
                            {userData?.hotel &&
                                <Grid item xs={12}>
                                    <MultipleSelect
                                        label="Notification setting"
                                        value={notificationSettings}
                                        values={new Map<string,string>([
                                            ["PENDING_BOOKING", "Pending Bookings"],
                                            ["URGENT_PENDING_BOOKING", "Urgent Pending Bookings"],
                                        ])}
                                        onSetIds={onSetNotificationSettings}
                                        defaultSelectedValue={notificationSettings?.map((s) => NotificationSetting[s as keyof typeof NotificationSetting])}
                                    />
                                </Grid>
                            }

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={deactivateUser}
                            onChange={event => setDeactivateUser(event.target.checked)}
                            onFocus={() =>
                                logAmplitudeEvent(BUTTON_DEACTIVATE_OR_ACTIVATE_USER)
                            }
                        />
                    }
                    label="Deactivate user"
                    sx={{ width: '100%', marginTop: '1rem' }}
                    disabled={user?.id === userData?.id}
                />
            </Grid>
            <Grid item xs={12} marginTop="2rem">
                <PrimaryButton
                    onClick={handleSave}
                    loading={isLoadingEdit || isFetchingUser}
                >
                    Save
                </PrimaryButton>
                <SecondaryButton onClick={handleBack} className={classes.buttonCancel}>
                    Back
                </SecondaryButton>
            </Grid>
            {isOpenModal && (
                <MessageDialog
                    title="Are you sure you want to edit?"
                    onClose={() => setIsOpenModal(false)}
                    onConfirm={() => handleConfirmSave()}
                    closeButtonLabel="Cancel"
                    confirmButtonLabel="Save"
                    isConfirmButtonDisplayed
                    closeButtonType="secondary"
                >
                    <Typography sx={{ padding: '1rem 0' }}>
                        You are about to edit this user. <br />
                        <strong>Are you sure you want to proceed?</strong>
                    </Typography>
                </MessageDialog>
            )}
        </div>
    );
};

export default EditPeople;
