import { FunctionComponent, PropsWithChildren } from 'react';
import { matchPath } from 'react-router-dom';

import {
    createCustomer,
    createHotel,
    customersAccounts,
    editAccount,
    editHotel,
    hotelsAccounts,
} from './pages/accounts';
import {
    deactivatedPeople,
    invitedPeople,
    activePeople,
    editPeople,
    accountActivePeople,
    accountInvitedPeople,
    accountDeactivatedPeople,
    hotelActiveUsers,
    hotelInvitedUsers,
    hotelDeactivatedUsers,
    ownHotelActiveUsers,
    ownHotelInvitedUsers,
    ownHotelDeactivatedUsers,
    editHotelUser,
} from './pages/people';
import {
    allBookings,
    archivedBookings,
    archiveRequests,
    createBooking, createRequest,
    editBooking,
    editRequest,
    inProgressBookings,
    inProgressRequests,
    pendingBookings,
    pendingRequests,
    upcomingBookings,
    upcomingRequests,
    viewBooking,
    viewRequest,
} from './pages/reservations';
import { billingStatements } from './pages/billing';
import { invoices } from './pages/invoices';
import { uploadInvoice } from './pages/uploadInvoice';

export interface BreadcrumbHeadNode {
    name: string | JSX.Element;
    previous?: BreadcrumbNode;
}
export interface BreadcrumbNode {
    name: string | JSX.Element;
    path: string;
    previous?: BreadcrumbNode;
}

function getParams<T extends string>(path: string): { [param in T]?: string } {
    const match = matchPath({ path }, window.location.pathname);

    if (!match) {
        throw new Error('Not a match');
    }

    return match.params;
}
export interface PageDefinition {
    path: string;
    Component: FunctionComponent<
        PropsWithChildren<{ getParam: (key: string) => string }>
    >;
}

const pages: PageDefinition[] = [
    hotelsAccounts,
    customersAccounts,
    createCustomer,
    createHotel,
    editAccount,
    editHotel,
    editPeople,
    activePeople,
    deactivatedPeople,
    invitedPeople,
    accountActivePeople,
    accountInvitedPeople,
    accountDeactivatedPeople,
    createRequest,
    pendingRequests,
    upcomingRequests,
    inProgressRequests,
    archiveRequests,
    viewRequest,
    editRequest,
    allBookings,
    pendingBookings,
    upcomingBookings,
    inProgressBookings,
    archivedBookings,
    createBooking,
    viewBooking,
    editBooking,
    hotelActiveUsers,
    hotelInvitedUsers,
    hotelDeactivatedUsers,
    ownHotelActiveUsers,
    ownHotelInvitedUsers,
    ownHotelDeactivatedUsers,
    editHotelUser,
    billingStatements,
    invoices,
    uploadInvoice
];

export default function createRoutes(): Array<{
    path: string;
    Component: FunctionComponent<PropsWithChildren<unknown>>;
}> {
    return pages.map(({ path, Component }) => ({
        path,
        Component: (): ReturnType<typeof Component> =>
            Component({
                getParam(handle) {
                    const paramValue = getParams(path)[handle];
                    if (typeof paramValue !== 'string') {
                        throw new Error(`Path does not contain parameter "${handle}"`);
                    }

                    return paramValue;
                },
            }),
    }));
}
