import { GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { COLORS } from '../../../constans/colors';

export const mockData = [
  { id: 1, dateRange: '1 Nov 2024 - 14 Nov 2024', relation: 'Ti QL airlines', statementAmount: 'CAD 150,000.00',roomNights: '1,200', downloadLink: '#' },
  { id: 2, dateRange: '15 Oct 2024 - 31 Oct 2024', relation: 'ABZ Travel', statementAmount: 'CAD 120,000.00',roomNights: '1,200', downloadLink: '#' },
  { id: 3, dateRange: '1 Sep 2024 - 14 Sep 2024', relation: 'XYZ Logistics', statementAmount: 'CAD 200,000.00', roomNights: '1,200', downloadLink: '#' },
  { id: 4, dateRange: '1 Aug 2024 - 14 Aug 2024', relation: 'LMN Corp', statementAmount: 'CAD 175,000.00', roomNights: '1,200', downloadLink: '#' },
  { id: 5, dateRange: '1 Jul 2024 - 14 Jul 2024', relation: 'DEF Airlines', statementAmount: 'CAD 190,000.00', roomNights: '1,200', downloadLink: '#' },
  { id: 6, dateRange: '1 Jun 2024 - 14 Jun 2024', relation: 'ABC Shipping', statementAmount: 'CAD 210,000.00', roomNights: '1,200', downloadLink: '#' },
];

export const columns: GridColDef[] = [
  { field: 'dateRange', headerName: 'Date Range', width: 200 },
  { field: 'relation', headerName: 'Relation', width: 200 },
  { field: 'statementAmount', headerName: 'Statement Amount', width: 180 },
  { field: 'roomNights', headerName: 'Statement room nights', width: 180 },
  {
    field: 'download',
    headerName: 'Download Statement',
    width: 1,
    flex: 1,
    renderCell: (params) => (
      <IconButton href={params.value} target="_blank">
        <DownloadIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />
      </IconButton>
    ),
    sortable: false,
    filterable: false,
  },
];