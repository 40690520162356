import { useFlags } from 'launchdarkly-react-client-sdk';

import SimpleLink from "../../components/SimpleLink";
import { UPLOAD_INVOICE } from "../../constans/urlPaths";
import { PageDefinition } from "../../createRoutes";
import Page from "../../layouts/Page";
import PermissionsGate from "../../permissions/PermissionsGate";
import UploadInvoice from "./UploadInvoice";
import { SCOPES } from '../../permissions/permissionMaps';

export const uploadInvoice: PageDefinition = {
    path: UPLOAD_INVOICE,
    Component() {
        const {useFinancialReconcilliation} = useFlags();

        return (
            useFinancialReconcilliation && <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Upload Invoice" link={UPLOAD_INVOICE} />,
                    }}
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        );
    },
};