import { useEffect } from 'react';
import {
    Autocomplete,
    FullScreenLoader,
    PrimaryButton,
    Spinner,
    TextField,
} from '@get-e/react-components';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    Grid,
    Stack,
    StandardTextFieldProps,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import moment, { Moment } from 'moment';
import { DateRange, DateRangePicker, TimePicker } from '@mui/x-date-pickers-pro';

import LabeledData from '../../../components/LabeledData';
import PageTitle from '../../../components/PageTitle';
import { COLORS } from '../../../constans/colors';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import { editBooking } from '../../../services/booking';
import { Benefits, BookingStatus } from '../api/types';
import { useBenefits } from '../../hotels/api/useBenefits';
import { Hotel } from '../../accounts/api/types';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import allValid from '../../../helpers/validation/allValid';
import { useHotelsNearby } from '../../accounts/api/useHotelsNearby';
import {
    DEAFULT_ADD_HOTEL_BUTTON_OPTION,
    NO_RESULTS_HOTEL_OPTION,
} from '../../hotels/api/constants';
import AddNewHotelModal from '../AddNewHotelModal';
import StatusButton from '../components/StatusButton';
import StatusList from '../components/StatusList';
import { getBookingStatusKeyName } from '../../../helpers/bookingStatusUtil';
import SaveBookingModal from '../components/SaveBookingModal';
import { useBooking } from '../api/useBooking';
import { ALL_HOTELS_NEARBY } from '../../../constans/queryKeys';
import { getRoundedDistance } from '../helpers/getRoundedDistance';
import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import * as emailLabels from '../../../constans/emailStatuses';
import {useAuth, useIsBackoffice} from "../../../context/AuthenticatedUserContext";
import and from '../../../helpers/validation/validators/and';
import isAfterTime from '../../../helpers/validation/validators/isAfterTime';
import { Validated } from '../../../helpers/validation/Validator';
import { BUTTON_CREATE_UPDATE_BOOKING_STATUS, BUTTON_INCLUDE_BREAKFAST, BUTTON_INCLUDE_PARKING, BUTTON_MAIL_TO_BOOKER_CONFIRMED, BUTTON_MAIL_TO_BOOKER_UPDATED, BUTTON_MAIL_TO_GUEST_CONFIRMED, BUTTON_MAIL_TO_GUEST_UPDATED, BUTTON_SAVE_BOOKING, INPUT_BOOKING_CONFIRMATION_NUMBER, INPUT_CHECKIN_TIME, INPUT_CHECKINOUT_DATE, INPUT_CHECKOUT_TIME, INPUT_HOTEL_NAME, INPUT_SPECIAL_REQUEST, SCREEN_CREATE_EDIT_BOOKING } from '../../../amplitude/amplKeys';
import { dayStyles, textFieldStyles } from '../../../styles/DateTimeRangePicker';

const useStyles = makeStyles(() => ({
    saveButton: {
        width: '150px',
    },
    bookingStatus: {
        lineHeight: '0.5rem',
    },
    statusList: {
        width: '100%',
        position: 'absolute',
        top: '3em',
        right: '0',
        zIndex: '100',
        backgroundColor: COLORS.WHITE,
        borderRadius: '5px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
    addNewContainer: {
        borderTop: `1px solid ${COLORS.DARK_GRAY}`,
        padding: '.75rem 1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    boxItem: {
        padding: '.75rem 1rem',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    searchLoader: {
        position: 'absolute',
        right: '40px',
        top: '45%',
        zIndex: '100',
    },
    noResults: {
        textAlign: 'center',
        padding: '.75rem 1rem',
    },
}));

const EditBooking = () => {
    const [isStatusListOpen, setIsStatusListOpen] = useState(false);
    const [bookingStatus, setBookingStatus] = useState<BookingStatus>();
    const [bookingConfirmationNumber, setBookingConfirmationNumber] =
        useState<string>('');
    const [defaultBookingConfirmationNumber, setDefaultBookingConfirmationNumber] =
        useState<string>('');

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Moment>>([null, null]);
    const [checkInTime, setCheckInTime] = useState<Moment | null>(null);
    const [checkOutTime, setCheckOutTime] = useState<Moment | null>(null);
    const [openInTime, setOpenInTime] = useState(false);
    const [openOutTime, setOpenOutTime] = useState(false);

    const [specialRequests, setSpecialRequests] = useState('');
    const [hotelComments, setHotelComments] = useState('');
    const [includeBreakfast, setIncludeBreakfast] = useState(false);
    const [includeParking, setIncludeParking] = useState(false);
    const [isTimeIncluded, setIsTimeIncluded] = useState(false);
    const [hotelIdError, setHotelIdError] = useState<InputError | null>(null);
    const [bookingConfirmationNumberError, setBookingConfirmationNumberError] =
        useState<InputError | null>(null);

    const [dateRangeError, setDateRangeError] = useState<InputError | null>(null);
    const [checkInTimeError, setCheckInTimeError] = useState<InputError | null>(null);
    const [checkOutTimeError, setCheckOutTimeError] = useState<InputError | null>(null);

    const [inputValue, setInputValue] = useState('');
    const [isOpenAddHotelModal, setIsOpenAddHotelModal] = useState(false);
    const [isOpenSaveBookingModal, setisOpenSaveBookingModal] = useState(false);
    const [isChangedDefaultHotel, setIsChangedDefaultHotel] = useState(false);
    const [hotel, setHotel] = useState<Hotel | null>(null);
    const [isLoadingData, setIsLoadingHotels] = useState(true);
    const [sendEmail, setSendEmail] = useState({
        requestedToHotel: true,
        updatedToHotel: true,
        cancelledToHotel: true,
        updatedToBooker: false,
        cancelledToBooker: false,
        confirmedToBooker: true,
        updatedToTraveller: false,
        cancelledToTraveller: false,
        confirmedToTraveller: true,
    });
    const {
        requestedToHotel,
        updatedToHotel,
        cancelledToHotel,
        updatedToBooker,
        cancelledToBooker,
        confirmedToBooker,
        updatedToTraveller,
        cancelledToTraveller,
        confirmedToTraveller,
    } = sendEmail;
    const { showNotification } = useNotificationContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();
    const { id = '' } = useParams();
    const isBackoffice = useIsBackoffice();

    const { data: booking, isFetched } = useBooking(parseInt(id, 10));

    const { hotels, refetch: refetchHotels } = useHotelsNearby({
        lat: booking?.hotelLatitude ?? '',
        long: booking?.hotelLongitude ?? '',
        search: inputValue,
        isEnabled: isFetched,
    });

    const isFetchingHotels = !!useIsFetching([ALL_HOTELS_NEARBY + inputValue]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_BOOKING);
    }, []);

    useEffect(() => {
        if (!isOpenAddHotelModal && !!inputValue) refetchHotels();
    }, [isOpenAddHotelModal]);

    useEffect(() => {
        if (booking) {
            setBookingConfirmationNumber(booking.bookingNumber);
            setDefaultBookingConfirmationNumber(booking.bookingNumber);
            const checkInTimeInitial = booking.isTimeIncluded
                ? moment(booking.checkInDateLocal)
                : null;
            const checkOutTimeInitial = booking.isTimeIncluded
                ? moment(booking.checkOutDateLocal)
                : null;

            setBookingStatus(booking.status);
            setSelectedDateRange([moment(booking.checkInDateLocal), moment(booking.checkOutDateLocal)])
            setCheckInTime(checkInTimeInitial);
            setCheckOutTime(checkOutTimeInitial);
            setSpecialRequests(booking.notes);
            setHotelComments(booking.hotelComments)
            setIncludeBreakfast(
                booking.benefits?.some(
                    benefit => benefit.name === Benefits.INCLUDE_BREAKFAST,
                ),
            );
            setIncludeParking(
                booking.benefits?.some(
                    benefit => benefit.name === Benefits.INCLUDE_PARKING,
                ),
            );
            const hotel = {
                id: booking?.hotelId,
                name: booking?.hotelName,
                hotelAddress: {
                    city: booking?.hotelCity,
                    state_province: booking?.hotelStateProvince,
                    postal_code: booking?.hotelPostalCode,
                    country_code: booking?.hotelCountryName,
                    address_line: booking?.hotelAddressLine,
                    lat: booking?.hotelLatitude,
                    long: booking?.hotelLongitude,
                    timeZone: booking?.timeZone,
                },
            };
            !isChangedDefaultHotel && setHotel(hotel as Hotel);
            setIsLoadingHotels(false);
        }
    }, [booking, isOpenAddHotelModal]);

    useEffect(() => {
        setSendEmail({
            ...sendEmail,
            requestedToHotel: true,
            updatedToHotel: true,
            cancelledToHotel: true,
            updatedToBooker: false,
            cancelledToBooker: false,
            confirmedToBooker: true,
            updatedToTraveller: false,
            cancelledToTraveller: false,
            confirmedToTraveller: true,
        });
    }, [bookingStatus]);

    const { benefits } = useBenefits();

    const hotelsOptions: Hotel[] = useMemo(() => {
        return [...hotels, DEAFULT_ADD_HOTEL_BUTTON_OPTION];
    }, [hotels]);

    useEffect(() => {
        setIsTimeIncluded(!!checkInTime || !!checkOutTime);
    }, [checkInTime, checkOutTime]);

    const guest = useMemo(() => {
        return booking?.bookingGuests[0];
    }, [booking?.bookingGuests]);

    const { user } = useAuth();

    const allowStatusChange = useMemo(() => {
        return (
            user?.isBackoffice &&
            booking?.status !== BookingStatus.BOOKING_CANCELLED &&
            booking?.status !== BookingStatus.COMPLETED
        );
    }, [booking?.status]);

    const { mutate: editBookingMutation, isLoading: isLoadingEdit } = useMutation(
        editBooking,
        {
            onSuccess: () => {
                showNotification('Booking successfully updated.', Severity.Info);
                navigate(-1);
            },
            onError: (error: Error) => {
                if(error.message === "The hotel change is not allowed regarding financial concerns.") {
                    showNotification("Hotel change is not allowed regarding financial concerns. Try cancelling and creating new booking!", Severity.Error);
                } else {
                    showNotification(t('errors.retry'), Severity.Error);
                }
            },
        },
    );

    const findBenefitId = (benefitName: Benefits): number => {
        const id = benefits?.find(benefit => benefit.name === benefitName)?.id;

        return id ? parseInt(id, 10) : 0;
    };

    const getBenefitIds = (): number[] => {
        const benefitIds: number[] = [];
        if (includeBreakfast) {
            const benefitId = findBenefitId(Benefits.INCLUDE_BREAKFAST);
            benefitId && benefitIds.push(benefitId);
        }
        if (includeParking) {
            const benefitId = findBenefitId(Benefits.INCLUDE_PARKING);
            benefitId && benefitIds.push(benefitId);
        }

        return benefitIds;
    };

    const validateFormFields = (): boolean => {
        setCheckInTimeError(null);
        setCheckOutTimeError(null);

        const validated = {
            hotel: isNotNull(hotel, InputError.Required),
            checkInOutDate: and(isNotNull(selectedDateRange?.[0], InputError.Required), () => isNotNull(selectedDateRange?.[1], InputError.Required)),
        } as any;

        const isSameDate =  selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) === selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']);
        if (isTimeIncluded) {
            validated.checkInTime = isNotNull(checkInTime, InputError.Required);
            validated.checkOutTime = and(isNotNull(checkOutTime, InputError.Required),
            () => isSameDate
            ? isAfterTime(checkOutTime?.toDate() || null, checkInTime?.toDate() || null, InputError.InvalidAfterTime) : { 
                isValid: true,
                value: new Date()
               } as Validated<Date, InputError>
            );
        }

        if (!allValid(validated)) {
            setHotelIdError(validated.hotel.isValid ? null : validated.hotel.error);
            setDateRangeError(validated.checkInOutDate.isValid ? null : InputError.Required);

            if (isTimeIncluded) {
                setCheckInTimeError(
                    validated.checkInTime.isValid ? null : validated.checkInTime.error,
                );
                setCheckOutTimeError(
                    validated.checkOutTime.isValid ? null : validated.checkOutTime.error,
                );
            }

            return false;
        }

        return true;
    };

    const isEmailing = updatedToHotel || updatedToTraveller || updatedToBooker;

    const handleSave = () => {
        logAmplitudeEvent(BUTTON_SAVE_BOOKING);
        if (
            (bookingStatus &&
                getBookingStatusKeyName(bookingStatus) ===
                    getBookingStatusKeyName(BookingStatus.BOOKING_CANCELLED)) ||
            isEmailing
        ) {
            validateAndConfirm();
        } else {
            validateAndUpdateBooking();
        }
    };

    const validateAndConfirm = () => {
        if (validateFormFields()) {
            setisOpenSaveBookingModal(true);
        }
    };

    const validateAndUpdateBooking = () => {
        if (booking && guest && bookingStatus && validateFormFields()) {
            editBookingMutation({
                id: booking.id,
                payload: {
                    status: getBookingStatusKeyName(bookingStatus),
                    hotelId: hotel?.id ?? 0,
                    checkInDate: selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
                    checkOutDate: selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
                    isTimeIncluded: isTimeIncluded,
                    checkInTime: isTimeIncluded
                        ? checkInTime?.format(TIME_FORMATS['HH:mm:ss']) || ''
                        : null,
                    checkOutTime: isTimeIncluded
                        ? checkOutTime?.format(TIME_FORMATS['HH:mm:ss']) || ''
                        : null,
                    notes: specialRequests,
                    hotelComments: hotelComments,
                    bookingNumber: bookingConfirmationNumber,
                    guestId: guest.id,
                    sendEmailRequestedToHotel:
                        bookingStatus === BookingStatus.BOOKING_PENDING
                            ? requestedToHotel
                            : false,
                    sendEmailUpdatedToHotel:
                        bookingStatus === BookingStatus.BOOKING_UPDATE_PENDING
                            ? updatedToHotel
                            : false,
                    sendEmailCancelledToHotel:
                        bookingStatus === BookingStatus.PROCESS_CANCELLATION ||
                        bookingStatus === BookingStatus.NOT_AVAILABLE
                            ? cancelledToHotel
                            : false,
                    sendEmailUpdatedToBooker: updatedToBooker,
                    sendEmailCancelledToBooker: cancelledToBooker,
                    sendEmailConfirmedToBooker:
                        bookingStatus === BookingStatus.BOOKING_CONFIRMED
                            ? confirmedToBooker
                            : false,
                    sendEmailUpdatedToTraveller: updatedToTraveller,
                    sendEmailCancelledToTraveller: cancelledToTraveller,
                    sendEmailConfirmedToTraveller:
                        bookingStatus === BookingStatus.BOOKING_CONFIRMED
                            ? confirmedToTraveller
                            : false,
                    benefitIds: getBenefitIds(),
                },
            });
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const toggleStatusList = () => {
        setIsStatusListOpen(prev => !prev);
    };

    const handleStatusItemClick = (status: keyof typeof BookingStatus) => {
        setBookingStatus(BookingStatus[status]);
        setIsStatusListOpen(false);
        logAmplitudeEvent(BUTTON_CREATE_UPDATE_BOOKING_STATUS);
    };

    const renderAddNewButton = () => {
        return (
            <Box
                component="li"
                className={classes.addNewContainer}
                onClick={() => setIsOpenAddHotelModal(true)}
            >
                + Add new
            </Box>
        );
    };

    const handleClickAway = () => {
        setIsStatusListOpen(false);
    };

    const handleConfirmSaveBooking = () => {
        validateAndUpdateBooking();
    };

    const handleCheckInTime = (newCheckInTime: Moment | null) => {
        setCheckInTime(newCheckInTime);
        setCheckInTimeError(null);
        fixCheckOutTime(newCheckInTime);
    };

    const fixCheckOutTime = (newCheckInTime: Moment | null) => {
        if (
            moment(selectedDateRange?.[0]).format(DATE_FORMATS['YYYY-MM-DD']) ===
            moment(selectedDateRange?.[1]).format(DATE_FORMATS['YYYY-MM-DD'])
        ) {
            if (moment(newCheckInTime).hour() > moment(checkOutTime).hour()) {
                setCheckOutTime(moment(newCheckInTime).add(1, 'hours'));
            }
            if (
                moment(newCheckInTime).hour() === moment(checkOutTime).hour() &&
                moment(newCheckInTime).minute() > moment(checkOutTime).minute()
            ) {
               setCheckOutTime(moment(newCheckInTime).add(30, 'minutes'));
            }
        }
    };

    if (isLoadingData) {
        return <FullScreenLoader />;
    }

    const handleHotelCreated = (hotel: Hotel) => {
        setHotel(hotel);
        setIsOpenAddHotelModal(false);
        setIsChangedDefaultHotel(true);
    };

    return (
        <>
            <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ marginBottom: '1.5rem' }}
            >
                <Grid item xs={12} md={8} xl={8}>
                    <PageTitle title="Update booking" />
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6} xl={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <LabeledData
                                        label="Booking information"
                                        labelColumnNumber={12}
                                        labelColor={COLORS.BLUE}
                                    />
                                </Grid>
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={6}
                                        textAlign={isMobile ? 'left' : 'right'}
                                        sx={{
                                            position: 'relative',
                                            paddingTop: isMobile
                                                ? '0 !important'
                                                : '1rem',
                                        }}
                                    >
                                        {booking?.status && (
                                            <>
                                                <StatusButton
                                                    className={classes.bookingStatus}
                                                    variant={bookingStatus}
                                                    onClick={
                                                        allowStatusChange
                                                            ? toggleStatusList
                                                            : undefined
                                                    }
                                                    showDropdownButton={allowStatusChange}
                                                    disablePulseEffect={
                                                        allowStatusChange ? false : true
                                                    }
                                                >
                                                    {bookingStatus ?? ''}
                                                </StatusButton>

                                                <StatusList
                                                    isListVisible={isStatusListOpen}
                                                    onItemClick={handleStatusItemClick}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </ClickAwayListener>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ position: 'relative', gridArea: 'gridItem2' }}
                                >
                                    {isFetchingHotels && (
                                        <Spinner
                                            className={classes.searchLoader}
                                            size={25}
                                        />
                                    )}
                                    <Autocomplete
                                        required
                                        filterOptions={options => {
                                            if (options.length === 1) {
                                                options.unshift(NO_RESULTS_HOTEL_OPTION);
                                            }
                                            return options;
                                        }}
                                        isError={hotelIdError !== null}
                                        helperText={getHelperText(hotelIdError, t)}
                                        label="Hotel name"
                                        loading={isLoadingData}
                                        options={hotelsOptions}
                                        getOptionLabel={(option: Hotel) => option.name}
                                        renderOption={(prop, option: Hotel) => {
                                            if (option.id === 'NO_RESULTS')
                                                return (
                                                    <Box
                                                        component="li"
                                                        {...prop}
                                                        className={classes.noResults}
                                                        onClick={() => {}}
                                                    >
                                                        No results found
                                                    </Box>
                                                );
                                            if (option.id === 'ADD')
                                                return <>{renderAddNewButton()}</>;

                                            return (
                                                <Box
                                                    component="li"
                                                    {...prop}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                    }}
                                                    className={classes.boxItem}
                                                >
                                                    <Typography
                                                        sx={{ marginRight: '1rem' }}
                                                    >
                                                        {option.name}{' '}
                                                        <span
                                                            style={{
                                                                color: COLORS.DARK_GRAY,
                                                            }}
                                                        >
                                                            {getRoundedDistance(
                                                                option?.distance_km ?? -1,
                                                            )}
                                                        </span>
                                                    </Typography>
                                                    <Typography
                                                        className={classes.secondaryText}
                                                    >
                                                        {option?.hotelAddress.toString()}
                                                    </Typography>
                                                </Box>
                                            );
                                        }}
                                        value={hotel}
                                        onChange={(_, newValue) => {
                                            setHotel(newValue ?? null);
                                            setHotelIdError(null);
                                            logAmplitudeEvent(INPUT_HOTEL_NAME)
                                            setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        noOptionsText={renderAddNewButton()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required={
                                            bookingStatus === 'Confirmed' ||
                                            !!defaultBookingConfirmationNumber
                                        }
                                        label="Booking confirmation number"
                                        value={bookingConfirmationNumber}
                                        onChange={event => {
                                            setBookingConfirmationNumber(
                                                event.target.value,
                                            );
                                            setBookingConfirmationNumberError(null);
                                        }}
                                        error={bookingConfirmationNumberError !== null}
                                        helperText={getHelperText(
                                            bookingConfirmationNumberError,
                                            t,
                                        )}
                                        onFocus={() =>
                                          logAmplitudeEvent(INPUT_BOOKING_CONFIRMATION_NUMBER)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <DateRangePicker
                                    localeText={{ start: 'Check-in', end: 'Check-out' }}
                                    value={selectedDateRange}
                                    onChange={newValue => {
                                        setSelectedDateRange(newValue);
                                        setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                        setDateRangeError(null);
                                        logAmplitudeEvent(INPUT_CHECKINOUT_DATE)
                                    }}
                                    format={DATE_FORMATS['DD MMM YYYY']}
                                    slotProps={{
                                        field: { dateSeparator: 'to' },
                                        textField: {
                                            ...textFieldStyles as StandardTextFieldProps,
                                            required: true,
                                            error: dateRangeError !== null,
                                            helperText: getHelperText(dateRangeError, t),
                                       },
                                       day: {
                                        sx: dayStyles,
                                      },
                                    }}
                                />
                                </Grid>
                                <Grid item xs={5.5}>
                                <TimePicker
                                     label="Check-in time"
                                     value={checkInTime}
                                     onChange={newValue => {
                                        handleCheckInTime(newValue);
                                        setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                    }}
                                     format={TIME_FORMATS['HH:mm A']}
                                     onAccept={() => setOpenInTime(true)}
                                     minutesStep={15}
                                     onClose={() => {
                                         logAmplitudeEvent(
                                            INPUT_CHECKIN_TIME
                                         )
                                         setOpenInTime(false);
                                        }
                                     }
                                     slotProps={{
                                        textField: {
                                            error: checkInTimeError !== null,
                                            helperText: getHelperText(checkInTimeError, t),
                                            variant: 'filled',
                                            inputProps: { readOnly: true },
                                            onClick: () => setOpenInTime(true),
                                        },
                                        field: { clearable: true },
                                    }}
                                    open={openInTime}
                                />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography></Typography>
                                </Grid>
                                <Grid item xs={5.5}>
                                <TimePicker
                                     label="Check-out time"
                                     value={checkOutTime}
                                     onChange={newValue => {
                                        setCheckOutTime(newValue);
                                        setCheckOutTimeError(null);
                                        setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                     }}
                                     format={TIME_FORMATS['HH:mm A']}
                                     onAccept={() => setOpenOutTime(true)}
                                     minutesStep={15}
                                     onClose={() => {
                                         logAmplitudeEvent(
                                            INPUT_CHECKOUT_TIME
                                         )
                                         setOpenOutTime(false);
                                        }
                                     }
                                     slotProps={{
                                        textField: {
                                            error: checkOutTimeError !== null,
                                            helperText: getHelperText(checkOutTimeError, t),
                                            variant: 'filled',
                                            inputProps: { readOnly: true },
                                            onClick: () => setOpenOutTime(true),
                                        },
                                        field: { clearable: true },
                                    }}
                                    open={openOutTime}
                                />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Special requests (optional)"
                                        value={specialRequests}
                                        onChange={event => {
                                            setSpecialRequests(event.target.value);
                                            setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                        }}
                                        multiline
                                        rows={4}
                                        onFocus={() =>
                                            logAmplitudeEvent( INPUT_SPECIAL_REQUEST )
                                        }
                                    />
                                </Grid>
                                {isBackoffice && 
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Hotel Comments"
                                            value={hotelComments}
                                            onChange={event => {
                                                setHotelComments(event.target.value);
                                            }}
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={includeBreakfast}
                                                onChange={event => {
                                                    setIncludeBreakfast(event.target.checked);
                                                    setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                                }}
                                                onFocus={() =>
                                                    logAmplitudeEvent(
                                                        BUTTON_INCLUDE_BREAKFAST
                                                    )
                                                }
                                            />
                                        }
                                        label="Include breakfast"
                                        sx={{ width: '100%' }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={includeParking}
                                                onChange={event => {
                                                    setIncludeParking(event.target.checked);
                                                    setBookingStatus(BookingStatus.BOOKING_UPDATE_PENDING);
                                                }}
                                                onFocus={() =>
                                                    logAmplitudeEvent(
                                                        BUTTON_INCLUDE_PARKING
                                                    )
                                                }
                                            />
                                        }
                                        label="Include parking"
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container columnSpacing={1} rowSpacing={2}>
                                <Grid item xs={12}>
                                    <LabeledData
                                        label="Traveller"
                                        labelColor={COLORS.BLUE}
                                    />
                                    <LabeledData
                                        label="Name"
                                        labelColor={COLORS.BLACK}
                                        data={`${guest?.first_name ?? ''} ${
                                            guest?.last_name ?? ''
                                        }`}
                                        dataSuffix={
                                            guest?.traveller?.employee_number?.toString() ??
                                            ''
                                        }
                                    />
                                    <LabeledData
                                        label="Phone number"
                                        labelColor={COLORS.BLACK}
                                        data={`${guest?.phone_number ?? ''}`}
                                    />
                                    <LabeledData
                                        label="Email"
                                        labelColor={COLORS.BLACK}
                                        data={`${guest?.email ?? ''}`}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    {isBackoffice && <Grid item sx={{ marginTop: '1rem' }}>
                        {bookingStatus === BookingStatus.BOOKING_PENDING && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sendEmail.requestedToHotel}
                                        onChange={event =>
                                            setSendEmail({
                                                ...sendEmail,
                                                requestedToHotel: event.target.checked,
                                            })
                                        }
                                    />
                                }
                                label={
                                    <Typography display="inline-flex">
                                        {emailLabels.SEND_EMAIL_TO_HOTEL}
                                        <Typography color={COLORS.SLATE_GREY}>
                                            <b>&nbsp;{emailLabels.SUBJECT}</b>
                                            {emailLabels.BOOKING_REQUESTED}
                                        </Typography>
                                    </Typography>
                                }
                                value={sendEmail.requestedToHotel}
                                sx={{ width: '100%' }}
                            />
                        )}
                        {bookingStatus === BookingStatus.BOOKING_UPDATE_PENDING && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={updatedToHotel}
                                        onChange={event =>
                                            setSendEmail({
                                                ...sendEmail,
                                                updatedToHotel: event.target.checked,
                                            })
                                        }
                                    />
                                }
                                label={
                                    <Typography display="inline-flex">
                                        {emailLabels.SEND_EMAIL_TO_HOTEL}
                                        <Typography color={COLORS.SLATE_GREY}>
                                            <b>&nbsp;{emailLabels.SUBJECT}</b>
                                            {emailLabels.BOOKING_UPDATED}
                                        </Typography>
                                    </Typography>
                                }
                                value={updatedToHotel}
                                sx={{ width: '100%' }}
                            />
                        )}
                        {bookingStatus === BookingStatus.BOOKING_CANCELLED && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cancelledToBooker}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    cancelledToBooker:
                                                        event.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_BOOKER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_CANCELLED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={cancelledToBooker}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={cancelledToTraveller}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    cancelledToTraveller:
                                                        event.target.checked,
                                                })
                                            }
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_TRAVELLER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_CANCELLED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={cancelledToTraveller}
                                    sx={{ width: '100%' }}
                                />
                            </>
                        )}
                        {bookingStatus === BookingStatus.BOOKING_CONFIRMED && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={confirmedToBooker}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    confirmedToBooker:
                                                        event.target.checked,
                                                })
                                            }
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    BUTTON_MAIL_TO_BOOKER_CONFIRMED
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_BOOKER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_CONFIRMED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={confirmedToBooker}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={updatedToBooker}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    updatedToBooker: event.target.checked,
                                                })
                                            }
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    BUTTON_MAIL_TO_BOOKER_UPDATED
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_BOOKER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_UPDATED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={updatedToBooker}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={confirmedToTraveller}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    confirmedToTraveller:
                                                        event.target.checked,
                                                })
                                            }
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    BUTTON_MAIL_TO_GUEST_CONFIRMED
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_TRAVELLER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_CONFIRMED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={confirmedToTraveller}
                                    sx={{ width: '100%' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={updatedToTraveller}
                                            onChange={event =>
                                                setSendEmail({
                                                    ...sendEmail,
                                                    updatedToTraveller:
                                                        event.target.checked,
                                                })
                                            }
                                            onFocus={() =>
                                                logAmplitudeEvent(
                                                    BUTTON_MAIL_TO_GUEST_UPDATED
                                                )
                                            }
                                        />
                                    }
                                    label={
                                        <Typography display="inline-flex">
                                            {emailLabels.SEND_EMAIL_TO_TRAVELLER}
                                            <Typography color={COLORS.SLATE_GREY}>
                                                <b>&nbsp;{emailLabels.SUBJECT}</b>
                                                {emailLabels.BOOKING_UPDATED}
                                            </Typography>
                                        </Typography>
                                    }
                                    value={updatedToTraveller}
                                    sx={{ width: '100%' }}
                                />
                            </>
                        )}

                        {(bookingStatus === BookingStatus.PROCESS_CANCELLATION ||
                            bookingStatus === BookingStatus.NOT_AVAILABLE) && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cancelledToHotel}
                                        onChange={event =>
                                            setSendEmail({
                                                ...sendEmail,
                                                cancelledToHotel: event.target.checked,
                                            })
                                        }
                                    />
                                }
                                label={
                                    <Typography display="inline-flex">
                                        {emailLabels.SEND_EMAIL_TO_HOTEL}
                                        <Typography color={COLORS.SLATE_GREY}>
                                            <b>&nbsp;{emailLabels.SUBJECT}</b>
                                            {emailLabels.BOOKING_CANCELLED}
                                        </Typography>
                                    </Typography>
                                }
                                value={cancelledToHotel}
                                sx={{ width: '100%' }}
                            />
                        )}
                        {bookingStatus === BookingStatus.COMPLETED ||
                            (bookingStatus === BookingStatus.SEARCHING_ROOMS && (
                                <Typography>No message to be sent.</Typography>
                            ))}
                    </Grid>}
                    <Stack spacing={2} direction="row" sx={{ marginTop: '1rem' }}>
                        <PrimaryButton
                            className={classes.saveButton}
                            onClick={handleSave}
                            loading={isLoadingEdit}
                        >
                            Save
                        </PrimaryButton>
                        <Button
                            variant="outlined"
                            onClick={handleBack}
                            sx={{
                                backgroundColor: COLORS.WHITE,
                                color: COLORS.BLACK,
                                width: '150px !important',
                                borderColor: COLORS.BLACK,
                            }}
                        >
                            Back
                        </Button>
                    </Stack>
                    {isOpenAddHotelModal && (
                        <AddNewHotelModal
                            isOpen={isOpenAddHotelModal}
                            onClose={() => {
                                setIsOpenAddHotelModal(false);
                            }}
                            onHotelCreated={handleHotelCreated}
                        />
                    )}
                    {isOpenSaveBookingModal && (
                        <SaveBookingModal
                            title="Edit booking"
                            isCancelling={
                                bookingStatus &&
                                getBookingStatusKeyName(bookingStatus) ===
                                    getBookingStatusKeyName(
                                        BookingStatus.BOOKING_CANCELLED,
                                    )
                            }
                            isSendingEmails={isEmailing}
                            onClose={() => {
                                setisOpenSaveBookingModal(false);
                            }}
                            onConfirm={handleConfirmSaveBooking}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default EditBooking;
